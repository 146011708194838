<template>
    <div class="attachment-upload">
        <div class="attachment-comp-upload">
            <el-upload
                class="attachment-comp-uploader"
                size="medium"
                ref="attachmentUploader"
                :action="action"
                :headers="headers"
                :accept="item.accept"
                :file-list="item.uploadValue"
                :show-file-list="false"
                :with-credentials="true"
                :before-upload="(file) => beforeUpload(file, item)"
                :on-success="(res, file, fileList) => handleSuccess(res, item, file, fileList)">
                <el-button type="primary" icon="el-icon-plus">附件上传</el-button>
            </el-upload>
            <span class="attachment-comp-tip">最多上传{{ item.few }}个附件且在{{ item.fileSize }}MB内</span>
        </div>
        <div class="attachment-comp-files">
            <div
                v-for="(item, index) in ruleForm[item.key]"
                :key="index"
                class="attachment-comp-file"
                @click="handlerPreview(item)">
                <div class="attachment-comp-file-icon">
                    <div v-if="item.suffix === 'loading'" v-loading="true" class="attachment-icon-img_img"></div>
                    <template v-else-if="['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(item.suffix) || item.type.startsWith('image/')">
                        <img class="attachment-icon-img attachment-icon-img_img" :src="imgAttachmentImg" alt="">
                        <span class="attachment-icon-label attachment-icon-label_img">{{ item.suffix }}</span>
                    </template>
                    <template v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf'].includes(item.suffix)">
                        <img class="attachment-icon-img attachment-icon-img_word" :src="imgAttachmentWord" alt="">
                        <span class="attachment-icon-label attachment-icon-label_word">{{ item.suffix }}</span>
                    </template>
                    <template v-else-if="['txt'].includes(item.suffix)">
                        <img class="attachment-icon-img attachment-icon-img_txt" :src="imgAttachmentTxt" alt="">
                        <span class="attachment-icon-label attachment-icon-label_txt">{{ item.suffix }}</span>
                    </template>
                    <template v-else-if="['zip', 'rar', '7z'].includes(item.suffix)">
                        <img class="attachment-icon-img attachment-icon-img_zip" :src="imgAttachmentZip" alt="">
                        <span class="attachment-icon-label attachment-icon-label_zip">{{ item.suffix }}</span>
                    </template>
                </div>
                <span class="attachment-comp-file-name">{{ item.name }}</span>
                <i class="el-icon-close attachment-comp-file-delete" @click.stop="handlerDelete(item, index)"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AttachmentUpload',
    components: {},
    props: ['ruleForm', 'item', 'action', 'headers'],
    data() {
        return {

        }
    },
    filters: {},
    computed: {
        imgAttachmentImg() {
            return require('@/assets/images/campusOA/attachment-img.png')
        },
        imgAttachmentWord() {
            return require('@/assets/images/campusOA/attachment-word.png')
        },
        imgAttachmentTxt() {
            return require('@/assets/images/campusOA/attachment-txt.png')
        },
        imgAttachmentZip() {
            return require('@/assets/images/campusOA/attachment-zip.png')
        }
    },
    watch: {},
    created() {
    },
    mounted() {
        console.log("[attachmentUpload]item:", this.item)
    },
    methods: {
        beforeUpload(file, data) {
            if (this.ruleForm[this.item.key] && this.ruleForm[this.item.key].length >= this.item.few) {
                this.$message.warning(`最多上传${this.item.few}个文件`)
                return false
            }
            console.log("[beforeUpload]file:", file)
            // const isAccept = data.accept.includes(file.type.split('/')[1])
            let fileNameSplitArr = file.name.split('.')
            let suffix = fileNameSplitArr[fileNameSplitArr.length - 1]
            const isAccept = data.accept.includes(suffix)
            const isSizePass = file.size / 1024 / 1024 < Number(data.fileSize)
            const isNamePass = file.name.length < 50
            if (!isAccept) {
                this.$message.error(`上传文件格式仅支持${data.accept}!`);
                return false
            }
            if (!isSizePass) {
                this.$message.error(`上传文件的大小不能超过 ${data.fileSize}M!`)
                return false
            }
            if (!isNamePass) {
                this.$message.error('上传文件名称不能超过50个字符!');
                return false
            }
            if(this.ruleForm[data.key] == undefined) {
                this.ruleForm[data.key] = [];
            }
            console.log(this.ruleForm,data.key,'data.key')
            this.ruleForm[data.key].push({
                name: file.name,
                url: '',
                type: file.type,
                suffix: 'loading',
                size: file.size,
                timestamp: +new Date(),
                uid: file.uid
            })
        },
        handleSuccess(res, data, file, fileList) {
            console.log(file,'file')
            // let fileInList = this.ruleForm[data.key].find(e => e.uid && e.uid === file.raw.uid)
            let fileInList = this.ruleForm[data.key].find(e => e.uid && e.uid === file.uid)
            if (fileInList) {
                fileInList.url = res.data.url
                let fileNameSplitArr = res.data.name.split('.')
                let suffix = fileNameSplitArr[fileNameSplitArr.length - 1]
                fileInList.suffix = suffix
                console.log("[handleSuccess]", this.ruleForm, data.uploadValue)
                this.$eventDispatch('handleChange', data)
            } else {
                console.log("[handleSuccess]上传文件已删除")
            }
        },
        // 预览
        handlerPreview(fileInfo) {
            //你的文档地址
            var file = fileInfo.url
            //XDOC文档预览服务地址
            var xurl = "https://view.xdocin.com/view?src=";
            //传入文档地址
            xurl += encodeURIComponent(file);
            //开始预览
            window.open(xurl, '_blank');
        },
        // 删除
        handlerDelete(fileInfo, index) {
            this.ruleForm[this.item.key].splice(index, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
.attachment-comp-upload {
    display: flex;

    .attachment-comp-tip {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #A6A6A6;
        margin-left: 16px;
    }
}

.attachment-comp-files {
    margin-top: 16px;

    .attachment-comp-file {
        height: 56px;
        background: #FFFFFF;
        border: 1px solid #DCDEE0;
        border-radius: 4px;
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        +.attachment-comp-file {
            margin-top: 12px;
        }

        .attachment-comp-file-icon {
            text-align: center;
            flex-shrink: 0;
            position: relative;
            width: 36px;
            height: 36px;

            .attachment-icon-label {
                position: absolute;
                bottom: 6px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 11px;
                color: #878787;
                line-height: 1;
            }

            .attachment-icon-img {
                object-fit: contain;
                display: inline-block;
            }

            .attachment-icon-img_img {
                width: 32px;
                height: 36px;
            }
            .attachment-icon-img_word {
                width: 36px;
                height: 36px;
            }
            .attachment-icon-img_txt {
                width: 36px;
                height: 36px;
            }
            .attachment-icon-img_zip {
                width: 32px;
                height: 36px;
            }
        }

        .attachment-comp-file-name {
            flex: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 16px;
            white-space: nowrap;
        }

        .attachment-comp-file-delete {
            font-size: 16px;
            color: #BFBFBF;
            font-weight: bold;
            margin-left: auto;

            &:hover {
                color: #e63a2e;
            }
        }
    }
}
</style>
