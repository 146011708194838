<template>
    <div class="date-half-picker">
        <el-date-picker
            style="flex: 1;"
            v-model="ruleForm[comp.key]"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="handleDatePickerChange"
        ></el-date-picker>
        <div class="spacer">-</div>
        <el-select
            style="flex-shrink: 0;width: 160px;"
            v-model="ruleForm[comp.key + 'NoonType']"
            placeholder="选择上午或下午"
            @change="handleSelectChange">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'dateHalfPicker',
    components: {},
    props: {
        comp: Object,
        ruleForm: Object,
    },
    data() {
        return {
            options: [
                {
                    label: '上午',
                    value: '1'
                },
                {
                    label: '下午',
                    value: '2'
                }
            ]
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleDatePickerChange(data) {
            console.log("[handleDatePickerChange]data:", data)
            this.$eventDispatch("dateHalfChange", {
                comp: this.comp,
                ruleForm: this.ruleForm,
                value: data,
                type: "date"
            })
        },
        handleSelectChange(data) {
            console.log("[handleSelectChange]data:", data)
            this.$eventDispatch("dateHalfChange", {
                comp: this.comp,
                ruleForm: this.ruleForm,
                value: data,
                type: "noon"
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.date-half-picker {
    display: flex;

    .spacer {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #2B2F33;
        margin: 0 6px;
    }
}
</style>
